<template>
<v-app-bar absolute dark elevation="0" :extension-height="!$vuetify.breakpoint.mobile ? 130: 70" color="#6DBF4A" height="auto" style="background-color: #6DBF4A;">
      <v-container class="fill-height" style="padding: 5px;" >
        <v-row style="padding:0px;">
        <v-col
        class=" text-black-2"
        cols="3" md="1" lg="1"
      >
      <!-- <v-select
          :items="[{title :'En', value : 'en'},{title :'Es', value : 'es'}]"
          item-text="title"
          item-value="value"
          @input="changeLang"
          style="height: 0px;margin-top: -2px;max-width: 50px;"
          v-model="lang"
        ></v-select> -->
        </v-col>
        <v-col
        class="text-right text-black-2"
        cols="9" md="11" lg="11"
      >
     
      <v-icon class="mr-3" size="15" @click="gotoLink('https://www.facebook.com/passprofile')" >
          fab fa-facebook
            </v-icon><v-icon class=" mr-3" size="15" @click="gotoLink('https://x.com/pass-profile')">
              fab fa-twitter
            </v-icon><v-icon class=" mr-3" size="15" @click="gotoLink('https://www.youtube.com/@passprofile')">
              fab fa-youtube
            </v-icon><v-icon class=" mr-3" size="15" @click="gotoLink('https://www.tiktok.com/passprofile')">
              fab fa-tiktok
            </v-icon><v-icon class=" mr-3" size="15" @click="gotoLink('https://www.linkedin.com/company/pass-profile')">
              fab fa-linkedin
            </v-icon><v-icon class="me-sm-1" size="15" @click="gotoLink('https://www.instagram.com/passprofile')">
              fab fa-instagram
            </v-icon>
          
        </v-col>
        </v-row>
      </v-container>
      <template #extension>
        <v-toolbar color="white" :height="!$vuetify.breakpoint.mobile ? 130 : 70" style="box-shadow: none;">
          <v-container>
            <v-row style="padding: 0px;">
              <v-col cols="3"> 
                <img v-if="!$vuetify.breakpoint.mobile" :height="80" style="margin-top: -25px;position:absolute;" class="image-logo2" src="@/assets/img/logo.png">
                <img v-if="$vuetify.breakpoint.mobile" :height="50" style="margin-top: 0px;position:absolute;" class="image-logo2" src="@/assets/img/logo.png">
              
              </v-col>
            <v-col cols="9" class="mx-auto text-right ">
          <template v-if="!$vuetify.breakpoint.mobile">
            <v-menu
              v-for="item in links"
              :key="item.name"
              :open-on-hover="!item.single"
              offset-y
              bottom
              min-width="360"
              max-width="600"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="item.name != 'Profile'"
                  v-bind="attrs"
                  v-on="on"
                  :ripple="false"
                  color="transparent"
                  :class="$route.path == item.link ? 'active-button' : 'text-black-2'"
                  class="
                    
                    font-weight
                    text-capitalize
                    ls-0
                    align-center
                  "
                  depressed
                  link
                  :to="item.link ? item.link : '#'"
                >
                  {{ item.name }}
                  <v-img
                  v-if="!item.single"
                    src="@/assets/img/down-arrow-white.svg"
                    alt="down-arrow"
                    class="arrow ms-1 d-lg-block d-none"
                  />
                </v-btn>
                  <v-btn
                  v-else
                  v-bind="attrs"
                  v-on="on"
                  :ripple="false"
                  color="transparent"
                  :class="$route.path == item.link ? 'active-button' : 'text-black-2'"
                  class="
                    
                    font-weight
                    text-capitalize
                    ls-0
                    align-center
                  "
                  depressed
                  link
                >
                  {{ item.name }}
                  <v-img
                  v-if="!item.single"
                    src="@/assets/img/down-arrow-white.svg"
                    alt="down-arrow"
                    class="arrow ms-1 d-lg-block d-none"
                  />
                </v-btn>
              </template>

              <v-list class="border-radius-xl overflow-hidden">
                <template v-if="item.name == 'Pages'">
                  <app-bar-pages></app-bar-pages>
                </template>
                <template v-if="item.name == 'Authentication'">
                  <app-bar-authentication></app-bar-authentication>
                </template>
                <template v-if="item.name == 'Applications'">
                  <app-bar-applications></app-bar-applications>
                </template>
                <template v-if="item.name == 'Ecommerce'">
                  <app-bar-ecommerce></app-bar-ecommerce>
                </template>
                <template v-if="item.name == 'Profile'">
                      <v-list>
                        <v-list-item>
                          <!-- <v-list-item-avatar> -->
                            <!-- <v-img src="https://cdn.vuetifyjs.com/images/john.png"></v-img> -->
                             <v-avatar size="100" class="my-3 ms-2">
                            <img v-if="!avatar" src="@/assets/img/avatar.png" alt="Brooklyn" />
                            <img v-else :src="avatar" alt="Brooklyn" />
                          </v-avatar>
                          <!-- </v-list-item-avatar> -->
                        </v-list-item>

                        <v-list-item link>
                          <v-list-item-content>
                            <v-list-item-title class="text-h3">
                              {{name}}
                            </v-list-item-title>
                            <v-list-item-subtitle>{{email}}</v-list-item-subtitle>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-icon>mdi-menu-down</v-icon>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                      <v-divider></v-divider>
                  <v-list class="px-3">
                   
                      <v-list-item v-for="item_child in item.pages" :key="item_child.title" class="mb-0 ps-0">
                        <v-btn
                          :ripple="false"
                          text
                          v-if="item_child.title != 'Logout'"
                          class="text-dark font-weight-normal text-capitalize ls-0"
                          depressed
                          link
                          :to="item_child.link"
                        >
                          <v-icon v-if="item_child.title != 'Logout'" size="16" class="material-icons-round text-dark opacity-6 me-3">
                            {{ item_child.icon }}
                          </v-icon>
                          <v-icon v-if="item_child.title == 'Logout'" size="16" class="material-icons-round active-button opacity-6 me-3">
                            {{ item_child.icon }}
                          </v-icon>
                          <span :class="item_child.title == 'Logout'  ? 'active-button' : ''">{{ item_child.title }}</span>
                          <v-spacer></v-spacer>
                        </v-btn>
                        <v-btn
                          :ripple="false"
                          text
                          v-if="item_child.title == 'Logout'"
                          class="text-dark font-weight-normal text-capitalize ls-0"
                          depressed
                          link
                          @click="logout()"
                        >
                          <v-icon v-if="item_child.title != 'Logout'" size="16" class="material-icons-round text-dark opacity-6 me-3">
                            {{ item_child.icon }}
                          </v-icon>
                          <v-icon v-if="item_child.title == 'Logout'" size="16" class="material-icons-round active-button opacity-6 me-3">
                            {{ item_child.icon }}
                          </v-icon>
                          <span :class="item_child.title == 'Logout'  ? 'active-button' : ''">{{ item_child.title }}</span>
                          <v-spacer></v-spacer>
                        </v-btn>
                      </v-list-item>
                    </v-list>
                </template>
                <template >
                  <v-list class="px-3">
                   
                      <v-list-item v-for="item_child in item.pages" :key="item_child.title" class="mb-0 ps-0">
                   
                        <v-btn
                          :ripple="false"
                          text
                          class="text-dark font-weight-normal text-capitalize ls-0"
                          depressed
                          link
                          @click="gotoLink(item_child.link_url)"
                        >
                         
                          {{ item_child.title }}
                      
                          <v-spacer></v-spacer>
                        </v-btn>
                      
                      </v-list-item>
                    </v-list>
                </template>
              </v-list>
            </v-menu>
          </template>
          <!-- <v-btn
            v-show="$vuetify.breakpoint.mobile"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-600
              text-capitalize
              drawer-toggler
              btn-toggler-hover
              py-3
              px-6
              rounded-sm
            "
            color="white"
            @click="dialog = true"
          >
        <v-icon class="me-sm-1" size="18" style="color: #6DBF4A;">
            fa fa-bars
          </v-icon>
          </v-btn> -->

          <v-dialog
            v-model="dialog"
            content-class="position-absolute top-0"
            width="95%"
          >
            <v-card class="card-shadow card-padding">
            
              <v-card-text class="card-padding pb-0 d-flex flex-column px-0">
                <v-list>
                      <v-list-group
                        v-for="item in links_mobile"
                        :key="item.title"
                        v-model="item.active"
                        no-action
                      >
                        <template v-slot:activator>
                          <v-list-item-content>
                            <v-list-item-title v-text="item.title" style="color:#6DBF4A"></v-list-item-title>
                          </v-list-item-content>
                        </template>

                        <v-list-item
                          v-for="child in item.items"
                          v-if="!item.single"
                          :key="child.title"
                        >
                          <v-list-item-content>
                            <v-list-item-title v-text="child.title"></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-group>
                      <v-list-item>
                        <v-btn
                          :ripple="false"
                          text
                          class="text-dark font-weight-normal text-capitalize ls-0"
                          depressed
                          link
                          :to="'login'"
                        >
                            <v-list-item-title style="color:#6DBF4A" >{{$t('header.menu.item_5.title')}}</v-list-item-title>
                            </v-btn>
                          </v-list-item>
                    </v-list>

                <div class="border-bottom my-7"></div>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
            </v-row>
          </v-container>
        </v-toolbar>
      </template>
    </v-app-bar>
    
</template>
<script>
// import AppBarPages from "./Widgets/AppBarPages.vue";
// // import AppBarAuthentication from "./Widgets/AppBarAuthentication.vue";
// // import AppBarApplications from "./Widgets/AppBarApplications.vue";
// import AppBarEcommerce from "./Widgets/AppBarEcommerce.vue";
// import AppBarDocs from "./Widgets/AppBarDocs.vue";

export default {
  name: "app-bar",
  components: {
    // AppBarPages,
    // AppBarAuthentication,
    // // AppBarApplications,
    // AppBarEcommerce,
    // AppBarDocs,
  },
  props: {
    background: String,
    hasBg: true,
    linkColor: String,
  },
  created () {
    console.log(this.$route.path);
    // this.getLinks();
  },
  methods: {
    changeLang(){
      localStorage.setItem('language',this.lang);
      location.reload()
    },
    loginAdmin(){
      if(confirm('Are you sure, You want to login as a admin?')){
            axios({
              method: "POST",
              
              url: "/api/auth/login-admin-client-admin",
              data: {
                email: this.from_admin_email            }
            })
              .then(response => {
                let data = response.data;
                if (data.flag == 1) {
                  let result = data.data;
                  localStorage.setItem("token", data.token.token);
                  localStorage.setItem("user_id", result.user_id);
                  localStorage.setItem("first_name", result.first_name);
                  localStorage.setItem("email", result.email);
                  localStorage.setItem("is_admin", result.is_admin);
                  localStorage.setItem("name", result.first_name+' '+result.last_name);
                  localStorage.setItem("role", JSON.stringify(result.roles[0]));
                  localStorage.setItem("company_name", result.company_name);
                  localStorage.setItem("no_tiptext_flag", '');
                  localStorage.setItem('company_logo',process.env.VUE_APP_WASBI_URL+result.logo);
                  localStorage.setItem("company_id", result.company_id);
                  this.$router.push("/admin/dashboard");
                  location.reload();
                } else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }
              })
              .catch(err => {
                Vue.$toast.error(err, {
                position: 'top-right'
                });
              });
          }
    },
    gotoLink(flag){
      location.replace(flag)
    },
    getLinks(){
          let headers = {
                Authorization: "Bearer " + localStorage.getItem('token')
            }
          axios
            .get("/api/get-all-link", {headers: headers})
            .then(response => {
              this.custom_links = response.data.data
              this.custom_links.forEach(val =>{
                if(val.client)
                  this.links[4].pages.push({
                      title: val.title,
                      icon: val.icon,
                      link: "/custom-link/"+val.id,
                      link_url  : val.url,
                      is_iframe : val.is_iframe
                    })
              })
              
            })
        },
        gotoLink(item){
          window.open(item, "_blank");
        },
    logout() {
      let self = this;
        let headers = {
            Authorization: "Bearer " + localStorage.getItem('token')
        }
      axios
        .get("/api/logout", {headers: headers})
        .then(response => {
            localStorage.removeItem("token")
            localStorage.removeItem("user_id");
            localStorage.removeItem("email");
            localStorage.removeItem("name");
            if(self.company.company_url)
              this.$router.push("/login/"+self.company.company_url);
            else
              this.$router.push("/");
            window.location.reload();
        })
        .catch(error => {
            localStorage.removeItem("token")
            localStorage.removeItem("user_id");
            localStorage.removeItem("email");
            localStorage.removeItem("name");
            this.$router.push("/");
            location.reload();
        });
    },
  },
  data() {
    return {
      dialog: false,
      plan : localStorage.getItem('plan') ,
      company : '',
      custom_links: [],
      lang : localStorage.getItem('language') ? localStorage.getItem('language') : 'en',
      name : localStorage.getItem('name'),
      user_id: localStorage.getItem('user_id'),
      email: localStorage.getItem('email'),
      avatar : localStorage.getItem('avatar') ? localStorage.getItem('avatar') : '',
      loadingLogo : false,
      from_admin : localStorage.getItem('from_admin') ? localStorage.getItem('from_admin') : 0,
      from_admin_email : localStorage.getItem('from_admin_email') ? localStorage.getItem('from_admin_email') : '',
      logo  : localStorage.getItem('company_logo'),
      company_id : localStorage.getItem('company_id') == "null" ? '' : localStorage.getItem('company_id'),
      doc_flag : localStorage.getItem('agreement_flag'),
      links_mobile : [
      {
          action: 'mdi-ticket',
          active: true,
          items: [
            { title: this.$t('header.menu.item_1.sub_title_1') },
            { title: this.$t('header.menu.item_1.sub_title_2') },
            { title: this.$t('header.menu.item_1.sub_title_3')},
          ],
          title: this.$t('header.menu.item_1.title'),
        },
        {
          action: 'mdi-silverware-fork-knife',
          active: true,
          items: [
            { title: this.$t('header.menu.item_2.sub_title_1') },
            { title: this.$t('header.menu.item_2.sub_title_2') },
            { title: this.$t('header.menu.item_2.sub_title_3') },
          ],
          title: this.$t('header.menu.item_2.title'),
        },
        {
          action: 'mdi-school',
          active: true,
          items: [{ title: this.$t('header.menu.item_3.sub_title_1') },
            { title: this.$t('header.menu.item_3.sub_title_2') },
            { title: this.$t('header.menu.item_3.sub_title_3') },],
          title: this.$t('header.menu.item_3.title'),
        },
        {
          action: 'mdi-human-male-female-child',
          active: true,
          items: [
          { title: this.$t('header.menu.item_4.sub_title_1') },
            { title: this.$t('header.menu.item_4.sub_title_2') },
            { title: this.$t('header.menu.item_4.sub_title_3') },
            { title: this.$t('header.menu.item_4.sub_title_4') },
            { title: this.$t('header.menu.item_4.sub_title_5') },
            { title: this.$t('header.menu.item_4.sub_title_6') },
            { title: this.$t('header.menu.item_4.sub_title_7') },
            { title: this.$t('header.menu.item_4.sub_title_8') },
            { title: this.$t('header.menu.item_4.sub_title_9') },
          ],
          title: this.$t('header.menu.item_4.title'),
        },
       
       
      ],
      links_web : [
      {
          action: 'mdi-ticket',
          active: true,
          items: [
            { title: this.$t('header.menu.item_1.sub_title_1') },
            { title: this.$t('header.menu.item_1.sub_title_2') },
            { title: this.$t('header.menu.item_1.sub_title_3')},
          ],
          title: this.$t('header.menu.item_1.title'),
        },
        {
          action: 'mdi-silverware-fork-knife',
          active: true,
          items: [
            { title: this.$t('header.menu.item_2.sub_title_1') },
            { title: this.$t('header.menu.item_2.sub_title_2') },
            { title: this.$t('header.menu.item_2.sub_title_3') },
          ],
          title: this.$t('header.menu.item_2.title'),
        },
        {
          action: 'mdi-school',
          active: true,
          items: [{ title: this.$t('header.menu.item_3.sub_title_1') },
            { title: this.$t('header.menu.item_3.sub_title_2') },
            { title: this.$t('header.menu.item_3.sub_title_3') },],
          title: this.$t('header.menu.item_3.title'),
        },
        {
          action: 'mdi-human-male-female-child',
          active: true,
          items: [
          { title: this.$t('header.menu.item_4.sub_title_1') },
            { title: this.$t('header.menu.item_4.sub_title_2') },
            { title: this.$t('header.menu.item_4.sub_title_3') },
            { title: this.$t('header.menu.item_4.sub_title_4') },
            { title: this.$t('header.menu.item_4.sub_title_5') },
            { title: this.$t('header.menu.item_4.sub_title_6') },
            { title: this.$t('header.menu.item_4.sub_title_7') },
            { title: this.$t('header.menu.item_4.sub_title_8') },
            { title: this.$t('header.menu.item_4.sub_title_9') },
          ],
          title: this.$t('header.menu.item_4.title'),
        },
       
       
      ],
      links: [
        
       
        // {
        //   name: 'Why Pass Profile?',
        //   link: "/why-pass-profile",
        //   single: true,
        // },
        
        // {
        //   name: 'About Us',
        //   link: "/about-us",
        //   single: true,
        // },
        {
          name: this.$t('header.menu.item_5.title'),
          single: true,
          link: "/login",
        },
        {
          name: 'Get Started',
          single: true,
          link: "/signup",
        },
       
      ],
    };
  },
};
</script>
<style>
.v-toolbar__content, .v-toolbar__extension {
  padding: 0;
}
.btn-dark-hover:hover, .btn-dark-hover:focus {
  color: #53AD57 !important;
}
.active-button{
  color : #FF5353 !important;
}
.text-black-2{
  color: rgba(0,0,0,.6)!important;
}
</style>